import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const TaxRegimeService = {
  getTaxsRagime (filter, page, itemsPerPage) {
    let queryString = httpBuildQuery(filter)
    page = page || 1
    itemsPerPage = itemsPerPage || 1000

    filter.page = page
    filter.per_page = itemsPerPage

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/regime-tributario?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getTaxRegime (idInvoice) {}
}

export default TaxRegimeService
